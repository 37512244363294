<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Company Profile</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button type="success" @click="updateForm"
                    >Save</base-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div>
                <!-- Company name -->
                <div class="row">
                  <div class="col-md-8">
                    <base-input
                      label="Company Name"
                      :required="true"
                      placeholder=""
                      v-model="companyName"
                    ></base-input>
                  </div>
                  <!-- Logo -->
                  <div class="col-md-4">
                    <base-image-input @image-selected="setLogoFile">
                      Choose Your logo
                    </base-image-input>
                  </div>
                </div>
                <!-- Industry -->
                <div class="row">
                  <div class="col-md-8">
                    <base-multiselect-dropdown
                      parentLabel="Industry"
                      :required="true"
                      v-model="industry.value"
                      :options="industry.options"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="false"
                      trackBy="id"
                      label="industry"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder=""
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Add Business Activities -->
                <div class="row">
                  <div class="col-md-8">
                    <base-multiselect-dropdown
                      parentLabel="Add Business Activities"
                      :required="true"
                      v-model="businessActivities.value"
                      :options="businessActivities.options"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="true"
                      trackBy="id"
                      label="activity"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder=""
                    >
                    </base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Business Type -->
                <div class="row">
                  <div class="col-md-8">
                    <base-multiselect-dropdown
                      parentLabel="Business Type"
                      :required="true"
                      v-model="businessType.value"
                      :options="businessType.options"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="false"
                      trackBy="id"
                      label="type"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder=""
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Company Size -->
                <div class="row">
                  <div class="col-md-8">
                    <base-multiselect-dropdown
                      parentLabel="Company Size"
                      :required="true"
                      v-model="companySize.value"
                      :options="companySize.options"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="false"
                      trackBy="id"
                      label="size"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder=""
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Website URL -->
                <div class="row">
                  <div class="col-md-8">
                    <base-input
                      label="Website URL"
                      :required="false"
                      placeholder=""
                      v-model="website"
                    ></base-input>
                  </div>
                </div>
                <!-- Social Media -->
                <div class="row">
                  <div class="col-md-8">
                    <base-input
                      label="Social Media URLs (Separated by commas)"
                      :required="false"
                      placeholder=""
                      v-model="socialMedia"
                    ></base-input>
                  </div>
                </div>
                <!-- Reg Address line1-->
                <div class="row">
                  <div class="col-md-8">
                    <base-input
                      label="Registered Address"
                      :required="false"
                      placeholder=""
                      v-model="regAddress.line1"
                    ></base-input>
                  </div>
                </div>
                <!-- City -->
                <div class="row">
                  <div class="col-md-4">
                    <base-input
                      :required="false"
                      placeholder="City"
                      v-model="regAddress.city"
                    ></base-input>
                  </div>
                  <!-- State/Province -->
                  <div class="col-md-4">
                    <base-input
                      :required="false"
                      placeholder="State/Province"
                      v-model="regAddress.state"
                    ></base-input>
                  </div>
                </div>
                <!-- ZIP code -->
                <div class="row">
                  <div class="col-md-4">
                    <base-input
                      :required="false"
                      placeholder="ZIP Code"
                      v-model="regAddress.zip"
                    ></base-input>
                  </div>
                  <!-- Country -->
                  <div class="col-md-4">
                    <base-multiselect-dropdown
                      :required="false"
                      v-model="regAddress.country.value"
                      :options="regAddress.country.options"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="false"
                      trackBy="number"
                      label="name"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder="Country"
                      open-direction="above"
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Operational Address toggle -->
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-control-label"
                      >Operational Address</label
                    >
                    <base-switch
                      label="Same as above?"
                      :value="operationalAdress.on"
                      @click="operationalAdress.on = !operationalAdress.on"
                    ></base-switch>
                  </div>
                </div>
                <!--  Operational Address-->
                <section v-show="!operationalAdress.on">
                  <!-- line 1 -->
                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        :required="false"
                        placeholder=""
                        v-model="operationalAdress.line1"
                      ></base-input>
                    </div>
                  </div>
                  <!-- City -->
                  <div class="row">
                    <div class="col-md-4">
                      <base-input
                        :required="false"
                        placeholder="City"
                        v-model="operationalAdress.city"
                      ></base-input>
                    </div>
                    <!-- State/Province -->
                    <div class="col-md-4">
                      <base-input
                        :required="false"
                        placeholder="State/Province"
                        v-model="operationalAdress.state"
                      ></base-input>
                    </div>
                  </div>
                  <!-- ZIP code -->
                  <div class="row">
                    <div class="col-md-4">
                      <base-input
                        :required="false"
                        placeholder="ZIP Code"
                        v-model="operationalAdress.zip"
                      ></base-input>
                    </div>
                    <!-- Country -->
                    <div class="col-md-4">
                      <base-multiselect-dropdown
                        :required="false"
                        v-model="operationalAdress.country.value"
                        :options="operationalAdress.country.options"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="true"
                        :multiple="false"
                        trackBy="number"
                        label="name"
                        :maxHeight="600"
                        :optionHeight="300"
                        placeholder="Country"
                        open-direction="above"
                      ></base-multiselect-dropdown>
                    </div>
                  </div>
                </section>
                <!-- Phone No. -->
                <div class="row">
                  <div class="col-md-4">
                    <base-input
                      :valid="isPhoneValid"
                      label="Phone No."
                      :required="false"
                      placeholder=""
                      v-model="phone.value"
                    ></base-input>
                  </div>
                  <!-- Phone Code -->
                  <div class="col-md-4">
                    <base-multiselect-dropdown
                      parentLabel="Code"
                      :required="false"
                      v-model="phone.country.value"
                      :options="phone.country.options"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="true"
                      :multiple="false"
                      trackBy="number"
                      label="phCode3"
                      :maxHeight="600"
                      :optionHeight="300"
                      placeholder="code"
                      open-direction="above"
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <label class="form-control-label">Add Tax Details</label>
                    <textarea
                      class="form-control"
                      v-model="taxDetails"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Write a large text here ..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { countries, defaultCountry } from "../plugins/countries";
export default {
  data() {
    return {
      saveClicked: false,
      companyName: "",
      website: "",
      socialMedia: "",
      taxDetails: "",
      industry: {
        value: "",
        options: [
          { industry: "Administration", id: "administration" },
          { industry: "Agriculture", id: "agriculture" },
          { industry: "Home Planning", id: "home-planning" },
          { industry: "IT", id: "it" },
        ],
      },
      businessActivities: {
        value: "",
        options: [
          { activity: "Administration", id: "administration" },
          { activity: "Agriculture", id: "agriculture" },
          { activity: "Home Planning", id: "home-planning" },
          { activity: "Education", id: "education" },
        ],
      },
      businessType: {
        value: { type: "Public", id: "public" },
        options: [
          { type: "Public", id: "public" },
          { type: "Private", id: "private" },
        ],
      },
      companySize: {
        value: { size: "10 to 49", id: "2" },
        options: [
          { size: "2 to 9", id: "1" },
          { size: "10 to 49", id: "2" },
          { size: "50 to 99", id: "3" },
          { size: "100 to 499", id: "4" },
          { size: "500+", id: "5" },
        ],
      },
      regAddress: {
        line1: "",
        city: "",
        state: "",
        zip: "",
        country: {
          value: defaultCountry,
          options: countries,
        },
      },
      operationalAdress: {
        on: true,
        line1: "",
        city: "",
        state: "",
        zip: "",
        country: {
          value: defaultCountry,
          options: countries,
        },
      },
      phone: {
        value: "",
        country: {
          value: defaultCountry,
          options: countries,
        },
      },
    };
  },
  computed: {
    isPhoneValid() {
      if (this.phone.value.length === 0) return true;
      if (this.saveClicked && this.phone.value.length < 11) return true;
      return false;
    },
  },
  methods: {
    setLogoFile(file) {
      console.log(file);
    },
    updateForm() {
      this.saveClicked = true;
    },
  },
};
</script>
<style scoped></style>
