<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Expenses</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    type="success"
                    @click="$router.push({ name: 'createExpense' })"
                    >Create</base-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewExpenses",
};
</script>
<style lang="scss" scoped></style>
