<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Invoices</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    type="success"
                    @click="$router.push({ name: 'createInvoice' })"
                    >Create</base-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="grid-header">
                <div>Invoice Id</div>
                <div>Created Date</div>
                <div>Amount</div>
                <div>Receipt No.</div>
                <div>Customer</div>
                <div>Payment Link</div>
                <div>Status</div>
              </div>
              <div
                class="grid-cells"
                @click="
                  $router.push({
                    name: 'invoicePdf',
                    params: { id: 'inv_Gh0C6TlYNDFwLw' },
                  })
                "
              >
                <div>inv_Gh0C6TlYNDFwLw</div>
                <div>28 Feb 2021</div>
                <div>$186.00</div>
                <div>123344</div>
                <div>Shubhankar</div>
                <div>https://rzp.io/i/97dK3U1q</div>
                <div>Paid</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewInvoices",
};
</script>
<style lang="scss" scoped>
.grid-header {
  display: grid;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  border-top: 1px solid #ddd;
  background-color: #eaeff0;
  border-bottom: 1px solid #ddd;
  div {
    justify-self: start;
    padding: 0.5rem;
  }
}
.grid-cells {
  &:hover {
    background-color: #f5f5f5;
  }
  display: grid;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  div {
    font-size: 0.8rem;
    justify-self: start;
    padding: 0.5rem;
  }
}
</style>
