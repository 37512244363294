// https://gist.github.com/incredimike/1469814
// https://gist.github.com/Goles/3196253
let countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "Afghanistan", number: "004", ph: "+93" },
  {
    code: "AX",
    code3: "ALA",
    name: "Åland Islands",
    number: "248",
    ph: "+358",
  },
  { code: "AL", code3: "ALB", name: "Albania", number: "008", ph: "+355" },
  { code: "DZ", code3: "DZA", name: "Algeria", number: "012", ph: "+213" },
  {
    code: "AS",
    code3: "ASM",
    name: "American Samoa",
    number: "016",
    ph: "+1684",
  },
  { code: "AD", code3: "AND", name: "Andorra", number: "020", ph: "+376" },
  { code: "AO", code3: "AGO", name: "Angola", number: "024", ph: "+244" },
  { code: "AI", code3: "AIA", name: "Anguilla", number: "660", ph: "+1264" },
  { code: "AQ", code3: "ATA", name: "Antarctica", number: "010", ph: "+672" },
  {
    code: "AG",
    code3: "ATG",
    name: "Antigua and Barbuda",
    number: "028",
    ph: "+1268",
  },
  { code: "AR", code3: "ARG", name: "Argentina", number: "032", ph: "+54" },
  { code: "AM", code3: "ARM", name: "Armenia", number: "051", ph: "+374" },
  { code: "AW", code3: "ABW", name: "Aruba", number: "533", ph: "+297" },
  { code: "AU", code3: "AUS", name: "Australia", number: "036", ph: "+61" },
  { code: "AT", code3: "AUT", name: "Austria", number: "040", ph: "+43" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031", ph: "+994" },
  {
    code: "BS",
    code3: "BHS",
    name: "Bahamas (the)",
    number: "044",
    ph: "+1242",
  },
  { code: "BH", code3: "BHR", name: "Bahrain", number: "048", ph: "+973" },
  { code: "BD", code3: "BGD", name: "Bangladesh", number: "050", ph: "+880" },
  { code: "BB", code3: "BRB", name: "Barbados", number: "052", ph: "+1246" },
  { code: "BY", code3: "BLR", name: "Belarus", number: "112", ph: "+375" },
  { code: "BE", code3: "BEL", name: "Belgium", number: "056", ph: "+32" },
  { code: "BZ", code3: "BLZ", name: "Belize", number: "084", ph: "+501" },
  { code: "BJ", code3: "BEN", name: "Benin", number: "204", ph: "+229" },
  { code: "BM", code3: "BMU", name: "Bermuda", number: "060", ph: "+1441" },
  { code: "BT", code3: "BTN", name: "Bhutan", number: "064", ph: "+975" },
  {
    code: "BO",
    code3: "BOL",
    name: "Bolivia (Plurinational State of)",
    number: "068",
    ph: "+591",
  },
  {
    code: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    number: "070",
    ph: "+387",
  },
  { code: "BW", code3: "BWA", name: "Botswana", number: "072", ph: "+267" },
  { code: "BR", code3: "BRA", name: "Brazil", number: "076", ph: "+55" },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory (the)",
    number: "086",
    ph: "+246",
  },
  {
    code: "BN",
    code3: "BRN",
    name: "Brunei Darussalam",
    number: "096",
    ph: "+673",
  },
  { code: "BG", code3: "BGR", name: "Bulgaria", number: "100", ph: "+359" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854", ph: "+226" },
  { code: "BI", code3: "BDI", name: "Burundi", number: "108", ph: "+257" },
  { code: "KH", code3: "KHM", name: "Cambodia", number: "116", ph: "+855" },
  { code: "CM", code3: "CMR", name: "Cameroon", number: "120", ph: "+237" },
  { code: "CA", code3: "CAN", name: "Canada", number: "124", ph: "+1" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132", ph: "+238" },
  {
    code: "KY",
    code3: "CYM",
    name: "Cayman Islands (the)",
    number: "136",
    ph: "+ 345",
  },
  {
    code: "CF",
    code3: "CAF",
    name: "Central African Republic (the)",
    number: "140",
    ph: "+236",
  },
  { code: "TD", code3: "TCD", name: "Chad", number: "148", ph: "+235" },
  { code: "CL", code3: "CHL", name: "Chile", number: "152", ph: "+56" },
  { code: "CN", code3: "CHN", name: "China", number: "156", ph: "+86" },
  {
    code: "CX",
    code3: "CXR",
    name: "Christmas Island",
    number: "162",
    ph: "+61",
  },
  {
    code: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands (the)",
    number: "166",
    ph: "+61",
  },
  { code: "CO", code3: "COL", name: "Colombia", number: "170", ph: "+57" },
  {
    code: "KM",
    code3: "COM",
    name: "Comoros (the)",
    number: "174",
    ph: "+269",
  },
  { code: "CG", code3: "COG", name: "Congo (the)", number: "178", ph: "+242" },
  {
    code: "CD",
    code3: "COD",
    name: "Congo (the Democratic Republic of the)",
    number: "180",
    ph: "+243",
  },
  {
    code: "CK",
    code3: "COK",
    name: "Cook Islands (the)",
    number: "184",
    ph: "+682",
  },
  { code: "CR", code3: "CRI", name: "Costa Rica", number: "188", ph: "+506" },
  {
    code: "CI",
    code3: "CIV",
    name: "Côte d'Ivoire",
    number: "384",
    ph: "+225",
  },
  { code: "HR", code3: "HRV", name: "Croatia", number: "191", ph: "+385" },
  { code: "CU", code3: "CUB", name: "Cuba", number: "192", ph: "+53" },
  { code: "CY", code3: "CYP", name: "Cyprus", number: "196", ph: "+357" },
  { code: "CZ", code3: "CZE", name: "Czechia", number: "203", ph: "+420" },
  { code: "DK", code3: "DNK", name: "Denmark", number: "208", ph: "+45" },
  { code: "DJ", code3: "DJI", name: "Djibouti", number: "262", ph: "+253" },
  { code: "DM", code3: "DMA", name: "Dominica", number: "212", ph: "+1767" },
  {
    code: "DO",
    code3: "DOM",
    name: "Dominican Republic (the)",
    number: "214",
    ph: "+1849",
  },
  { code: "EC", code3: "ECU", name: "Ecuador", number: "218", ph: "+593" },
  { code: "EG", code3: "EGY", name: "Egypt", number: "818", ph: "+20" },
  { code: "SV", code3: "SLV", name: "El Salvador", number: "222", ph: "+503" },
  {
    code: "GQ",
    code3: "GNQ",
    name: "Equatorial Guinea",
    number: "226",
    ph: "+240",
  },
  { code: "ER", code3: "ERI", name: "Eritrea", number: "232", ph: "+291" },
  { code: "EE", code3: "EST", name: "Estonia", number: "233", ph: "+372" },
  { code: "ET", code3: "ETH", name: "Ethiopia", number: "231", ph: "+251" },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands (the) [Malvinas]",
    number: "238",
    ph: "+500",
  },
  {
    code: "FO",
    code3: "FRO",
    name: "Faroe Islands (the)",
    number: "234",
    ph: "+298",
  },
  { code: "FJ", code3: "FJI", name: "Fiji", number: "242", ph: "+679" },
  { code: "FI", code3: "FIN", name: "Finland", number: "246", ph: "+358" },
  { code: "FR", code3: "FRA", name: "France", number: "250", ph: "+33" },
  {
    code: "GF",
    code3: "GUF",
    name: "French Guiana",
    number: "254",
    ph: "+594",
  },
  {
    code: "PF",
    code3: "PYF",
    name: "French Polynesia",
    number: "258",
    ph: "+689",
  },
  { code: "GA", code3: "GAB", name: "Gabon", number: "266", ph: "+241" },
  { code: "GM", code3: "GMB", name: "Gambia (the)", number: "270", ph: "+220" },
  { code: "GE", code3: "GEO", name: "Georgia", number: "268", ph: "+995" },
  { code: "DE", code3: "DEU", name: "Germany", number: "276", ph: "+49" },
  { code: "GH", code3: "GHA", name: "Ghana", number: "288", ph: "+233" },
  { code: "GI", code3: "GIB", name: "Gibraltar", number: "292", ph: "+350" },
  { code: "GR", code3: "GRC", name: "Greece", number: "300", ph: "+30" },
  { code: "GL", code3: "GRL", name: "Greenland", number: "304", ph: "+299" },
  { code: "GD", code3: "GRD", name: "Grenada", number: "308", ph: "+1473" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312", ph: "+590" },
  { code: "GU", code3: "GUM", name: "Guam", number: "316", ph: "+1671" },
  { code: "GT", code3: "GTM", name: "Guatemala", number: "320", ph: "+502" },
  { code: "GG", code3: "GGY", name: "Guernsey", number: "831", ph: "+44" },
  { code: "GN", code3: "GIN", name: "Guinea", number: "324", ph: "+224" },
  {
    code: "GW",
    code3: "GNB",
    name: "Guinea-Bissau",
    number: "624",
    ph: "+245",
  },
  { code: "GY", code3: "GUY", name: "Guyana", number: "328", ph: "+595" },
  { code: "HT", code3: "HTI", name: "Haiti", number: "332", ph: "+509" },
  {
    code: "VA",
    code3: "VAT",
    name: "Holy See (the)",
    number: "336",
    ph: "+379",
  },
  { code: "HN", code3: "HND", name: "Honduras", number: "340", ph: "+504" },
  { code: "HK", code3: "HKG", name: "Hong Kong", number: "344", ph: "+852" },
  { code: "HU", code3: "HUN", name: "Hungary", number: "348", ph: "+36" },
  { code: "IS", code3: "ISL", name: "Iceland", number: "352", ph: "+354" },
  { code: "IN", code3: "IND", name: "India", number: "356", ph: "+91" },
  { code: "ID", code3: "IDN", name: "Indonesia", number: "360", ph: "+62" },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran (Islamic Republic of)",
    number: "364",
    ph: "+98",
  },
  { code: "IQ", code3: "IRQ", name: "Iraq", number: "368", ph: "+964" },
  { code: "IE", code3: "IRL", name: "Ireland", number: "372", ph: "+353" },
  { code: "IM", code3: "IMN", name: "Isle of Man", number: "833", ph: "+44" },
  { code: "IL", code3: "ISR", name: "Israel", number: "376", ph: "+972" },
  { code: "IT", code3: "ITA", name: "Italy", number: "380", ph: "+39" },
  { code: "JM", code3: "JAM", name: "Jamaica", number: "388", ph: "+1876" },
  { code: "JP", code3: "JPN", name: "Japan", number: "392", ph: "+81" },
  { code: "JE", code3: "JEY", name: "Jersey", number: "832", ph: "+44" },
  { code: "JO", code3: "JOR", name: "Jordan", number: "400", ph: "+962" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398", ph: "+77" },
  { code: "KE", code3: "KEN", name: "Kenya", number: "404", ph: "+254" },
  { code: "KI", code3: "KIR", name: "Kiribati", number: "296", ph: "+686" },
  {
    code: "KP",
    code3: "PRK",
    name: "Korea (the Democratic People's Republic of)",
    number: "408",
    ph: "+850",
  },
  {
    code: "KR",
    code3: "KOR",
    name: "Korea (the Republic of)",
    number: "410",
    ph: "+82",
  },
  { code: "KW", code3: "KWT", name: "Kuwait", number: "414", ph: "+965" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417", ph: "+996" },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic (the)",
    number: "418",
    ph: "+856",
  },
  { code: "LV", code3: "LVA", name: "Latvia", number: "428", ph: "+371" },
  { code: "LB", code3: "LBN", name: "Lebanon", number: "422", ph: "+961" },
  { code: "LS", code3: "LSO", name: "Lesotho", number: "426", ph: "+266" },
  { code: "LR", code3: "LBR", name: "Liberia", number: "430", ph: "+231" },
  { code: "LY", code3: "LBY", name: "Libya", number: "434", ph: "+218" },
  {
    code: "LI",
    code3: "LIE",
    name: "Liechtenstein",
    number: "438",
    ph: "+423",
  },
  { code: "LT", code3: "LTU", name: "Lithuania", number: "440", ph: "+370" },
  { code: "LU", code3: "LUX", name: "Luxembourg", number: "442", ph: "+352" },
  { code: "MO", code3: "MAC", name: "Macao", number: "446", ph: "+853" },
  {
    code: "MK",
    code3: "MKD",
    name: "Republic of North Macedonia",
    number: "807",
    ph: "+389",
  },
  { code: "MG", code3: "MDG", name: "Madagascar", number: "450", ph: "+261" },
  { code: "MW", code3: "MWI", name: "Malawi", number: "454", ph: "+265" },
  { code: "MY", code3: "MYS", name: "Malaysia", number: "458", ph: "+60" },
  { code: "MV", code3: "MDV", name: "Maldives", number: "462", ph: "+960" },
  { code: "ML", code3: "MLI", name: "Mali", number: "466", ph: "+223" },
  { code: "MT", code3: "MLT", name: "Malta", number: "470", ph: "+356" },
  {
    code: "MH",
    code3: "MHL",
    name: "Marshall Islands (the)",
    number: "584",
    ph: "+692",
  },
  { code: "MQ", code3: "MTQ", name: "Martinique", number: "474", ph: "+596" },
  { code: "MR", code3: "MRT", name: "Mauritania", number: "478", ph: "+222" },
  { code: "MU", code3: "MUS", name: "Mauritius", number: "480", ph: "+230" },
  { code: "YT", code3: "MYT", name: "Mayotte", number: "175", ph: "+262" },
  { code: "MX", code3: "MEX", name: "Mexico", number: "484", ph: "+52" },
  {
    code: "FM",
    code3: "FSM",
    name: "Micronesia (Federated States of)",
    number: "583",
    ph: "+691",
  },
  {
    code: "MD",
    code3: "MDA",
    name: "Moldova (the Republic of)",
    number: "498",
    ph: "+373",
  },
  { code: "MC", code3: "MCO", name: "Monaco", number: "492", ph: "+377" },
  { code: "MN", code3: "MNG", name: "Mongolia", number: "496", ph: "+976" },
  { code: "ME", code3: "MNE", name: "Montenegro", number: "499", ph: "+382" },
  { code: "MS", code3: "MSR", name: "Montserrat", number: "500", ph: "+1664" },
  { code: "MA", code3: "MAR", name: "Morocco", number: "504", ph: "+212" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508", ph: "+258" },
  { code: "MM", code3: "MMR", name: "Myanmar", number: "104", ph: "+95" },
  { code: "NA", code3: "NAM", name: "Namibia", number: "516", ph: "+264" },
  { code: "NR", code3: "NRU", name: "Nauru", number: "520", ph: "+674" },
  { code: "NP", code3: "NPL", name: "Nepal", number: "524", ph: "+977" },
  {
    code: "NL",
    code3: "NLD",
    name: "Netherlands (the)",
    number: "528",
    ph: "+31",
  },
  {
    code: "NC",
    code3: "NCL",
    name: "New Caledonia",
    number: "540",
    ph: "+687",
  },
  { code: "NZ", code3: "NZL", name: "New Zealand", number: "554", ph: "+64" },
  { code: "NI", code3: "NIC", name: "Nicaragua", number: "558", ph: "+505" },
  { code: "NE", code3: "NER", name: "Niger (the)", number: "562", ph: "+227" },
  { code: "NG", code3: "NGA", name: "Nigeria", number: "566", ph: "+234" },
  { code: "NU", code3: "NIU", name: "Niue", number: "570", ph: "+683" },
  {
    code: "NF",
    code3: "NFK",
    name: "Norfolk Island",
    number: "574",
    ph: "+672",
  },
  {
    code: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands (the)",
    number: "580",
    ph: "+1670",
  },
  { code: "NO", code3: "NOR", name: "Norway", number: "578", ph: "+47" },
  { code: "OM", code3: "OMN", name: "Oman", number: "512", ph: "+968" },
  { code: "PK", code3: "PAK", name: "Pakistan", number: "586", ph: "+92" },
  { code: "PW", code3: "PLW", name: "Palau", number: "585", ph: "+680" },
  {
    code: "PS",
    code3: "PSE",
    name: "Palestine, State of",
    number: "275",
    ph: "+970",
  },
  { code: "PA", code3: "PAN", name: "Panama", number: "591", ph: "+507" },
  {
    code: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
    number: "598",
    ph: "+675",
  },
  { code: "PY", code3: "PRY", name: "Paraguay", number: "600", ph: "+595" },
  { code: "PE", code3: "PER", name: "Peru", number: "604", ph: "+51" },
  {
    code: "PH",
    code3: "PHL",
    name: "Philippines (the)",
    number: "608",
    ph: "+63",
  },
  { code: "PN", code3: "PCN", name: "Pitcairn", number: "612", ph: "+872" },
  { code: "PL", code3: "POL", name: "Poland", number: "616", ph: "+48" },
  { code: "PT", code3: "PRT", name: "Portugal", number: "620", ph: "+351" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630", ph: "+1939" },
  { code: "QA", code3: "QAT", name: "Qatar", number: "634", ph: "+974" },
  { code: "RO", code3: "ROU", name: "Romania", number: "642", ph: "+40" },
  {
    code: "RU",
    code3: "RUS",
    name: "Russian Federation (the)",
    number: "643",
    ph: "+7",
  },
  { code: "RW", code3: "RWA", name: "Rwanda", number: "646", ph: "+250" },
  { code: "RE", code3: "REU", name: "Réunion", number: "638", ph: "+262" },
  {
    code: "BL",
    code3: "BLM",
    name: "Saint Barthélemy",
    number: "652",
    ph: "+590",
  },
  {
    code: "SH",
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    number: "654",
    ph: "+290",
  },
  {
    code: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    number: "659",
    ph: "+1869",
  },
  { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662", ph: "+1758" },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    number: "663",
    ph: "+590",
  },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    number: "666",
    ph: "+508",
  },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    number: "670",
    ph: "+1784",
  },
  { code: "WS", code3: "WSM", name: "Samoa", number: "882", ph: "+685" },
  { code: "SM", code3: "SMR", name: "San Marino", number: "674", ph: "+378" },
  {
    code: "ST",
    code3: "STP",
    name: "Sao Tome and Principe",
    number: "678",
    ph: "+239",
  },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682", ph: "+966" },
  { code: "SN", code3: "SEN", name: "Senegal", number: "686", ph: "+221" },
  { code: "RS", code3: "SRB", name: "Serbia", number: "688", ph: "+381" },
  { code: "SC", code3: "SYC", name: "Seychelles", number: "690", ph: "+248" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694", ph: "+232" },
  { code: "SG", code3: "SGP", name: "Singapore", number: "702", ph: "+65" },
  { code: "SK", code3: "SVK", name: "Slovakia", number: "703", ph: "+421" },
  { code: "SI", code3: "SVN", name: "Slovenia", number: "705", ph: "+386" },
  {
    code: "SB",
    code3: "SLB",
    name: "Solomon Islands",
    number: "090",
    ph: "+677",
  },
  { code: "SO", code3: "SOM", name: "Somalia", number: "706", ph: "+252" },
  { code: "ZA", code3: "ZAF", name: "South Africa", number: "710", ph: "+27" },
  { code: "SS", code3: "SSD", name: "South Sudan", number: "728", ph: "+211" },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    number: "239",
    ph: "+500",
  },
  { code: "ES", code3: "ESP", name: "Spain", number: "724", ph: "+34" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144", ph: "+94" },
  { code: "SD", code3: "SDN", name: "Sudan (the)", number: "729", ph: "+249" },
  { code: "SR", code3: "SUR", name: "Suriname", number: "740", ph: "+597" },
  {
    code: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    number: "744",
    ph: "+47",
  },
  { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748", ph: "+268" },
  { code: "SE", code3: "SWE", name: "Sweden", number: "752", ph: "+46" },
  { code: "CH", code3: "CHE", name: "Switzerland", number: "756", ph: "+41" },
  {
    code: "SY",
    code3: "SYR",
    name: "Syrian Arab Republic",
    number: "760",
    ph: "+963",
  },
  { code: "TW", code3: "TWN", name: "Taiwan", number: "158", ph: "+886" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762", ph: "+992" },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania, United Republic of",
    number: "834",
    ph: "+255",
  },
  { code: "TH", code3: "THA", name: "Thailand", number: "764", ph: "+66" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626", ph: "+670" },
  { code: "TG", code3: "TGO", name: "Togo", number: "768", ph: "+228" },
  { code: "TK", code3: "TKL", name: "Tokelau", number: "772", ph: "+690" },
  { code: "TO", code3: "TON", name: "Tonga", number: "776", ph: "+676" },
  {
    code: "TT",
    code3: "TTO",
    name: "Trinidad and Tobago",
    number: "780",
    ph: "+1868",
  },
  { code: "TN", code3: "TUN", name: "Tunisia", number: "788", ph: "+216" },
  { code: "TR", code3: "TUR", name: "Turkey", number: "792", ph: "+90" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795", ph: "+993" },
  {
    code: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands (the)",
    number: "796",
    ph: "+1649",
  },
  { code: "TV", code3: "TUV", name: "Tuvalu", number: "798", ph: "+688" },
  { code: "UG", code3: "UGA", name: "Uganda", number: "800", ph: "+256" },
  { code: "UA", code3: "UKR", name: "Ukraine", number: "804", ph: "+380" },
  {
    code: "AE",
    code3: "ARE",
    name: "United Arab Emirates (the)",
    number: "784",
    ph: "+971",
  },
  {
    code: "GB",
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    number: "826",
    ph: "+44",
  },
  {
    code: "US",
    code3: "USA",
    name: "United States of America (the)",
    number: "840",
    ph: "+1",
  },
  { code: "UY", code3: "URY", name: "Uruguay", number: "858", ph: "+598" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860", ph: "+998" },
  { code: "VU", code3: "VUT", name: "Vanuatu", number: "548", ph: "+678" },
  {
    code: "VE",
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)",
    number: "862",
    ph: "+58",
  },
  { code: "VN", code3: "VNM", name: "Viet Nam", number: "704", ph: "+84" },
  {
    code: "VG",
    code3: "VGB",
    name: "Virgin Islands (British)",
    number: "092",
    ph: "+1284",
  },
  {
    code: "VI",
    code3: "VIR",
    name: "Virgin Islands (U.S.)",
    number: "850",
    ph: "+1340",
  },
  {
    code: "WF",
    code3: "WLF",
    name: "Wallis and Futuna",
    number: "876",
    ph: "+681",
  },
  { code: "YE", code3: "YEM", name: "Yemen", number: "887", ph: "+967" },
  { code: "ZM", code3: "ZMB", name: "Zambia", number: "894", ph: "+260" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716", ph: "+263" },
];
countryListAllIsoData = countryListAllIsoData.map((el) => {
  return { ...el, phCode3: `(${el.ph}) ${el.name}` };
});
const defaultCountry = countryListAllIsoData.find((e) => e.code3 === "GBR");
export { countryListAllIsoData as countries, defaultCountry };
