<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col-8">
          <div class="card shadow">
            <div class="card-body py-5 px-5">
              <!-- Merchant Name -->
              <div class="mb-6">
                <h3 class="merchant_name">Shubhankar Sardar</h3>
              </div>
              <!-- Invoice ID -->
              <div class="mb-6">
                <h3 class="invoice_id_label">Invoice</h3>
                <span class="invoice_id">
                  Receipt: 123344
                </span>
              </div>
              <!-- Amount Due -->
              <div class="amount-due_container mb-6">
                <label class="amount-due_header">AMOUNT DUE:</label>
                <h3 class="amount-due amount">
                  $0.00
                </h3>
              </div>
              <!-- Billing To & Address -->
              <div class="row">
                <!-- Billing To -->
                <div class="col-6 billing_to">
                  <div class="mb-4">
                    <label>BILLING TO</label>
                    <div>Neil Vaz</div>
                  </div>
                  <div class="mb-4">
                    <label>ISSUE DATE</label>
                    <div>28 Feb 2021</div>
                  </div>
                  <div class="mb-4">
                    <label>EXPIRY DATE</label>
                    <div>09 Mar 2021</div>
                  </div>
                </div>
                <!-- Billing Address -->
                <div class="col-6 billing_addr">
                  <label>BILLING ADDRESS</label>
                  <div>Oasis, JS Noronha MArg, Vadavalli</div>
                  <div>Dargah Naka, Palle</div>
                  <div>Vasai (W), MH, India</div>
                  <div>401201</div>
                </div>
              </div>
              <!-- Table of line items -->
              <div class="table-responsive">
                <table class="table inv__itemtable">
                  <thead>
                    <tr>
                      <th class="lineItem__item">DESCRIPTION</th>
                      <th class="lineItem__amount text-right">UNIT PRICE</th>
                      <th class="lineItem__qty text-right">QTY</th>
                      <th class="lineItem__total text-right">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="lineItem__item">
                        <div>
                          <span class="item-name">Orange</span>
                        </div>
                        <div class="item-desc">
                          Ripesn from the teeews ioof the udgsh
                        </div>
                      </td>
                      <td class="lineItem__amount rate text-right">
                        <span class="amount">$34.00</span>
                      </td>
                      <td class="lineItem__qty qty text-right">1</td>
                      <td class="lineItem__total amount text-right">
                        <span class="amount">$34.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="lineItem__item">
                        <div>
                          <span class="item-name">Crates</span>
                        </div>
                        <div class="item-desc">
                          Sluggish Lorem ipsum abosulthe monoramic frames
                        </div>
                      </td>
                      <td class="lineItem__amount rate text-right">
                        <span class="amount">$200.00</span>
                      </td>
                      <td class="lineItem__qty qty text-right">1</td>
                      <td class="lineItem__total amount text-right">
                        <span class="amount">$200.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table">
                <tbody>
                  <tr class="total total-amount-due">
                    <td class="text-right"><b>Total</b></td>
                    <td class="inv-total text-right" width="30%">
                      <b class="amount">$234.00</b>
                    </td>
                  </tr>

                  <tr class="text-success">
                    <td class="text-right">
                      <b>Amount Paid</b>
                    </td>
                    <td class="inv-total text-right" width="30%">
                      <b class="amount">$234.00</b>
                    </td>
                  </tr>

                  <tr class="total">
                    <td class="text-right"><b>Amount Due</b></td>
                    <td class="inv-total text-right" width="30%">
                      <b class="amount">₹ 0.00</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Download nd Print -->
        <div class="col-4">
          <div class="dowload_invoice">
            <i class="ni ni-cloud-download-95 mr-2"></i>
            <span>Downlaod Invoice</span>
          </div>
          <div class="print_invoice">
            <i class="fa fa-print mr-2"></i>
            <span>Print Invoice</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InvoicePdf",
};
</script>
<style lang="scss" scoped>
h3 {
  font-size: 20px;
}
.card {
  font-family: "Nunito Sans", Helvetica, sans-serif;
  //   min-height: 90vw;
  max-width: 750px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.87);
}
.merchant_name {
  font-weight: 700;
}
.invoice_id_label {
  display: inline;
  font-weight: 800;
}
.invoice_id {
  font-size: 14px;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 600;
}
.amount-due_container {
  position: relative;
  align-items: center;
  display: flex;
  label {
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    display: block;
    position: relative;
  }
  .amount-due_header {
    text-decoration: underline;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
}
.billing_to,
.billing_addr {
  label {
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    display: block;
    position: relative;
  }
  div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.45;
  }
}
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
.table.inv__itemtable tbody tr:nth-of-type(even) {
  background-color: #fafafa;
}
.table.inv__itemtable thead {
  background-color: #fafafa;
}
.dowload_invoice,
.print_invoice {
  order: 1px solid transparent;
  border: 1px solid;
  font-size: 16px;
  border-radius: 2px;
  line-height: inherit;
  width: 80%;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color 150ms linear;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-weight: 400;
  background-color: #fafafa;
  border-color: #ddd;
  &:hover {
    background-color: #ededed;
  }
}
</style>
